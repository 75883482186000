import React, { useRef, useEffect, useState } from 'react';
import './Body.css';
import ProjectItem from './ProjectItem';
import InvisibleTreehouseBio from './PortfolioBios/InvisibleTreehouseBio';

function Body() {
  const myRef = useRef();
  const [visible, setVisible] = useState(false);
  const [projectTitle, setProjectTitle] = useState('');
  const [projectBio, setProjectBio] = useState('');

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(true)
        } else {
          setVisible(false)
        }
      })
    }, {
      threshold: 0.1
    })
    observer.observe(myRef.current)
  }, []);

  function setTreehouseData() {
    setProjectTitle("Invisible Treehouse");
    setProjectBio(`Invisible Treehouse is an innovative music streaming platform that incorporates
    a built-in NFT marketplace, empowering artists to upload and distribute their music directly to listeners worldwide. 
    With the added benefit of leveraging IPFS (InterPlanetary File System) for streaming, artists can share their creations 
    seamlessly and without intermediaries. The platform also enables artists to mint their uploaded songs as ERC1155 tokens 
    with the option to set a limited quantity of up to 10,000. Additionally, artists have the opportunity to establish ongoing 
    royalty fees of up to 10% for future transactions involving their music NFTs.`);
  }

  function setCashewInfo() {
    setProjectTitle("Cashew Gets a Job");
    setProjectBio(`Cashew Gets a Job is an interactive digital experience that blends storytelling with Web3 technology. 
      At its core, it's a heartwarming children's book about Cashew, a determined pup on a mission to find her place in the world. 
      Built on Solana, the project introduces an NFT collection where collectors can connect their wallets to access a dedicated portal, 
      view their Cashew NFTs, and claim real-life books. By integrating the Shopify Admin API, NFT holders receive exclusive discount codes, 
      seamlessly bridging the digital and physical worlds. The platform also features a fully functional Shopify-powered storefront
       where anyone can purchase books and other merchandise. Developed from the ground up, the website leverages modern web technologies, 
       including JavaScript, React, Tailwind CSS, Liquid code, and Netlify CMS, ensuring a smooth and easily manageable experience for both 
       collectors and non-technical users.`);
  }

  return (
    <div className='body' id='portfolio'>
      <div className='shimmer body_portfolio_background'>
        portfolio
      </div>
      <div ref={myRef} className={`${visible ? 'body_reveal' : 'body_reveal_inactive'}`}>
        <div className='page_body_container'>
          <div className='body_wrapper'>
              {window.innerWidth < 768 ? (
                <div className='body_content'>
                  {projectTitle && (
                    <div className='info' id='info-mobile'>
                        <InvisibleTreehouseBio projectBio={projectBio} projectTitle={projectTitle} />
                    </div>
                  )}
                  <div className='body_items'>
                  <div>
                      <ProjectItem
                      src="images/CashewGetsaJob.png"
                      text="Cashew Gets a Job"
                      link="https://cashewgetsajob.com/"
                      handleInfoClick={setCashewInfo}
                      />
                    </div>
                    <div>
                      <ProjectItem
                      src="images/InvisibleTreehouseImage.png"
                      text="Invisible Treehouse"
                      link="https://invisible-treehouse.web.app/"
                      handleInfoClick={setTreehouseData}
                      />
                    </div>
                  </div>
                  <div className='placeholder'>
                    <p>Click on an image to view project details</p>
                  </div>
                </div>
              ) : (
                <div className='body_content'>
                  <div className='info' id='info'>
                    {projectTitle ? (
                      <InvisibleTreehouseBio projectBio={projectBio} projectTitle={projectTitle} />
                    ) : (
                      <div className='placeholder'>
                        <p>Click on an image to view project details</p>
                      </div>
                    )}
                  </div>
                  <div className='body_items'>
                  <div>
                      <ProjectItem
                      src="images/CashewGetsaJob.png"
                      text="Cashew Gets a Job"
                      link="https://cashewgetsajob.com/"
                      handleInfoClick={setCashewInfo}
                      />
                    </div>
                    <div>
                      <ProjectItem
                      src="images/InvisibleTreehouseImage.png"
                      text="Invisible Treehouse"
                      link="https://invisible-treehouse.web.app/"
                      handleInfoClick={setTreehouseData}
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Body