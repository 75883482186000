import React from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import "./AboutMe.css";

gsap.registerPlugin(MotionPathPlugin);

function AboutMe() {
  useGSAP(() => {
    const paths = document.querySelectorAll("#paths path");
    paths.forEach((path, index) => {
      const length = path.getTotalLength(); // Get path length for smooth movement

      gsap.fromTo(
        `.circle-${index}`,
        { 
          motionPath: { path, align: path, autoRotate: true, start: 0 },
          opacity: 0, 
        },
        {
          motionPath: {
            path,
            align: path,
            autoRotate: true,
            start: 0,
            end: 1,
          },
          opacity: 1,
          duration: 6 + Math.random() * 2, // Randomize duration for variety
          repeat: -1,
          ease: "back.out",
          delay: index * 0.5, // Stagger start times
          onComplete: () => {
            gsap.to(`.circle-${index}`, {
              opacity: 0,
              duration: 0.5,
              ease: "back.out",
            })
          }
        }
      );
    });
  }, []);

  return (
    <div className="aboutme" id="home">
      <div className="aboutme__svg_container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <defs>
            <radialGradient
              gradientUnits="userSpaceOnUse"
              cx="297.881"
              cy="284.219"
              r="246.377"
              id="gradient-0"
              gradientTransform="matrix(2.261239, -0.001936, 0.000856, 1, -408.064623, 0.457307)"
            >
              <stop offset="0" style={{ stopColor: "rgb(71, 255, 0)" }}></stop>
              <stop offset="1" style={{ stopOpacity: 0, stopColor: "rgb(35, 35, 35)" }}></stop>
            </radialGradient>
          </defs>
          <g id="paths">
            <path fill="none" strokeWidth="1px" stroke="url(#gradient-0)" d="M 413.191 2.137 C 413.191 2.137 479.121 231.088 420.768 308.795 C 362.415 386.502 193.931 216.032 0.551 335.547"></path>
            <path fill="none" strokeWidth="1px" stroke="url(#gradient-0)" d="M 412.612 1.329 C 412.612 1.329 484.108 248.47 429.899 324.298 C 375.69 400.126 169.929 224.136 -1.099 335.479"></path>
            <path fill="none" strokeWidth="1px" stroke="url(#gradient-0)" d="M 412.752 1.097 C 412.752 1.097 476.095 215.854 414.873 294.094 C 353.651 372.334 202.747 213.989 1.011 334.422"></path>
            <path fill="none" strokeWidth="1px" stroke="url(#gradient-0)" d="M 412.848 0.418 C 412.848 0.418 494.599 276.641 439.405 344.721 C 384.211 412.801 143.189 230.305 -3.247 337.859"></path>
          </g>
          <g id="circles">
            <circle className="circle-0" cx="0" cy="0" r="3" fill="rgb(71, 255, 0)"></circle>
            <circle className="circle-1" cx="0" cy="0" r="3" fill="rgb(71, 255, 0)"></circle>
            <circle className="circle-2" cx="0" cy="0" r="3" fill="rgb(71, 255, 0)"></circle>
            <circle className="circle-3" cx="0" cy="0" r="3" fill="rgb(71, 255, 0)"></circle>
          </g>
        </svg>
      </div>
      <div className="aboutme_container">
        <div className="aboutme_wrapper">
          <h1>Wyatt Chamberlin</h1>
        </div>
        <div className="aboutme_dev_wrapper">
          <h5>Full-stack dev / Solidity dev</h5>
        </div>
        <div className="aboutme_background_hello">
          <p className="shimmer background_hello">hello</p>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
